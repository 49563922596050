<script>
import { Line, mixins } from "vue-chartjs";
import { calendarDateFormat, hourMinSec, AmPm } from "@/mixins/appMixins";
const { reactiveProp } = mixins;
import moment from "moment";
export default {
  extends: Line,
  mixins: [calendarDateFormat, hourMinSec, AmPm, reactiveProp],
  props: ["view", "options"],
  data() {
    return {
      datacollection: {
        datasets: [],
      },
      chartOptions: {
        scales: {
          yAxes: [
            {
              ticks: {
                fontColor: "#fff",
                min: 85,
                max: 100,
                stepSize: 5,
              },
              gridLines: {
                drawBorder: false,
              },
            },
          ],
          xAxes: [
            {
              ticks: {
                fontColor: "#fff",
              },
              type: "time",
              time: {
                unit: "minute",
                stepSize: "5",
              },
            },
          ],
        },
        tooltips: {
          enabled: true,
          backgroundColor: "#18b0b8",
          callbacks: {
            title: function () {
              return null;
            },
            label: function (tooltipItem) {
              return (
                moment(tooltipItem.xLabel).format("ddd-h:mm A") +
                " (" +
                tooltipItem.yLabel +
                "%" +
                ")"
              );
            },
          },
        },
        legend: {
          display: false,
        },
        responsive: true,
        maintainAspectRatio: false,
      },
    };
  },

  mounted() {
    this.renderLineChart();
  },
  watch: {
    chartData: function () {
      this._chart.destroy();
      this.renderLineChart();
    },
  },
  methods: {
    renderLineChart: function () {
      this.datacollection.datasets.push(this.chartData);
      this.renderChart(this.datacollection, this.chartOptions);
    },
  },
};
</script>s
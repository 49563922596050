<script>
import { Line, mixins } from "vue-chartjs";
import { calendarDateFormat, hourMinSec, AmPm } from "@/mixins/appMixins";
const { reactiveProp } = mixins;
//import moment from 'moment'
export default {
  extends: Line,
  mixins: [calendarDateFormat, hourMinSec, AmPm, reactiveProp],
  props: ["view", "options"],
  data() {
    return {
      datacollection: {
        datasets: [],
      },
    };
  },

  mounted() {
    this.renderLineChart();
  },
  watch: {
    chartData: function () {
      this._chart.destroy();
      this.renderLineChart();
    },
  },
  methods: {
    renderLineChart: function () {
      this.datacollection.datasets.push(this.chartData);
      this.renderChart(this.datacollection, this.options);
    },
  },
};
</script>s
<template>
  <div class="site-wrapper">
    <div class="a-main a-main--home bg-linear">
      <div class="container">
        <div class="a-head">
          <div class="a-head__back align-self-center">
            <router-link class="btn-link" :to="{ path: 'home' }">
              <BackArrowSvg />
            </router-link>
          </div>
        </div>
        <div class="data-page-title mb__22 d-flex">
          <div class="data-page-title__img align-self-center">
            <HeartSvg :width="37" :height="34" :viewBox="'0 0 37 34'" />
          </div>
          <div
            class="data-page-title__title color-white align-self-center ml-20"
          >
            <h2 class="page-title">PluseOx</h2>
            <p
              class="page-sub-title page-sub-title--one mb-0"
              v-show="this.view == 'Today'"
            >
              PluseOx data in Last 24 hours
            </p>
            <p
              class="page-sub-title page-sub-title--two mb-0"
              v-show="this.view == 'week'"
            >
              PluseOx data in Last 7 Days
            </p>
            <p
              class="page-sub-title page-sub-title--three mb-0"
              v-show="this.view == 'month'"
            >
              PluseOx data in Last 30 Days
            </p>
          </div>
        </div>

        <div class="data-chart">
          <div class="data-chart__toggler mb__22 d-flex justify-content-end">
            <div
              class="index-tab index-tab--one"
              v-bind:class="[this.view == 'Today' ? 'index-active' : '']"
              @click="onViewClick('Today')"
            >
              Today
            </div>
            <div
              class="index-tab index-tab--two"
              v-bind:class="[this.view == 'week' ? 'index-active' : '']"
              @click="onViewClick('week')"
            >
              Week
            </div>
            <div
              class="index-tab index-tab--three"
              v-bind:class="[this.view == 'month' ? 'index-active' : '']"
              @click="onViewClick('month')"
            >
              Month
            </div>
          </div>

          <div class="data-chart__chart">
            <Day v-if="this.view == 'Today'" :chartData="this.chartData" />
            <Week
              v-if="this.view == 'week'"
              :chartData="this.chartData"
              :options="this.chartOptions"
            />
            <Month
              v-if="this.view == 'month'"
              :chartData="this.chartData"
              :options="this.chartOptions"
            />
          </div>

          <div class="data-chart__pager">
            <ul class="h-pager d-flex justify-content-center">
              <li
                class="h-pager__list h-pager__list--one"
                v-bind:class="[this.view == 'Today' ? 'data-active' : '']"
                @click="onViewClick('Today')"
              ></li>
              <li
                class="h-pager__list h-pager__list--two"
                v-bind:class="[this.view == 'week' ? 'data-active' : '']"
                @click="onViewClick('week')"
              ></li>
              <li
                class="h-pager__list h-pager__list--three"
                v-bind:class="[this.view == 'month' ? 'data-active' : '']"
                @click="onViewClick('month')"
              ></li>
            </ul>
          </div>

          <div class="pulse-data color-white">
            <h4 class="pulse-data__date">{{ getCurrentDate }}</h4>
            <div class="pulse-data__par">
              <p class="pulse-data__label">Average Pulse Today</p>
              <div class="average-data">
                {{ this.spo2.length ? averageSpo2.toFixed() : 0 }}%
              </div>
              <p class="pulse-data__rate">
                Heart Beat Rate:
                {{
                  Object.keys(this.dailies).length > 0
                    ? this.dailies.averageHeartRate.toFixed()
                    : 0
                }}
                <span>bpm</span>
              </p>
            </div>
          </div>
        </div>
        <Exercise />
        <FruitOfTheDay />
      </div>
    </div>
  </div>
</template>
<script>
import Day from "../components/pulseOx/chart/Day";
import Week from "../components/pulseOx/chart/Week";
import Month from "../components/pulseOx/chart/Month";
import Exercise from "../components/home/Exercise";
import FruitOfTheDay from "../components/home/FruitOfTheDay";
import HeartSvg from "@/components/svg/HeartSvg";
import BackArrowSvg from "@/components/svg/BackArrowSvg";
import {
  calendarDateFormat,
  AmPm,
  garminPromptMixin,
} from "@/mixins/appMixins";
import moment from "moment";
import { mapState, mapActions } from "vuex";
export default {
  mixins: [calendarDateFormat, AmPm, garminPromptMixin],
  components: {
    FruitOfTheDay,
    Exercise,
    HeartSvg,
    BackArrowSvg,
    Day,
    Week,
    Month,
  },
  data() {
    return {
      view: "",
      pulses: null,
      spo2: [],
      loading: false,
      chartData: {
        data: [],
        lineTension: 0,
        label: "PulseOX",
        borderColor: "#f24428",
        pointBackgroundColor: "#f24428",
        borderWidth: 2,
        fill: false,
      },
      chartOptions: {
        scales: {
          yAxes: [
            {
              ticks: {
                fontColor: "#fff",
                min: 85,
                max: 100,
                stepSize: 5,
              },
              gridLines: {
                drawBorder: false,
              },
            },
          ],
          xAxes: [
            {
              ticks: {
                fontColor: "#fff",
              },
              type: "time",
              time: {
                unit: "day",
              },
            },
          ],
        },
        tooltips: {
          enabled: true,
          backgroundColor: "#18b0b8",
          callbacks: {
            title: function () {
              return null;
            },
            label: function (tooltipItem) {
              return (
                moment(tooltipItem.xLabel).format("MMM-DD-ddd") +
                " (" +
                tooltipItem.yLabel +
                "%" +
                ")"
              );
            },
          },
        },
        legend: {
          display: false,
        },
        responsive: true,
        maintainAspectRatio: false,
      },
    };
  },
  async mounted() {
    await this.checkGarminStatus();
    if (this.garminStatus) {
      this.pullPulseOxData();
      this.pullDailies();
    } else {
      this.promptGarminConnect();
    }
  },
  computed: {
    ...mapState("garmin", ["dailies", "garminStatus"]),
    ...mapState("user", ["profile"]),
    averageSpo2: function () {
      if (this.spo2.length) {
        let total = 0;
        let count = 0;
        this.spo2.map((r) => {
          total = total + r.y;
          count++;
        });
        return total / count;
      }
      return 0;
    },
    getCurrentDate: function () {
      return moment().format("MMM D, YYYY - dddd");
    },
  },
  methods: {
    ...mapActions("garmin", [
      "pullPulseOx",
      "pullPulseOxAverage",
      "pullDailies",
      "checkGarminStatus",
    ]),
      ...mapActions(["setLoading"]),
    onViewClick($day) {
      if(this.garminStatus){
      if ($day === "week" || $day === "month") {
        this.getWeekMonthData($day);
      } else {
        this.pullPulseOxData();
      }
      }else {
        this.promptGarminConnect();
      }
    },
    async pullPulseOxData() {
      try {
       this.setLoading(true)
        let res = await this.pullPulseOx(this.getDateMonthYear(moment()));
        this.chartData.data = [];
        if (!res.error) {
          this.pulses = res.data;
          this.spo2 = res.spo2;
          this.chartData.data = res.spo2.map((r) => {
            r["x"] =
              this.pulses.calendarDate +
              " " +
              this.get24(this.pulses.calendarDate, r.x);
            return r;
          });
        } else {
          this.$toaster.error(res.msg);
        }
        this.view = "Today";
       this.setLoading(false)
      } catch (e) {
        this.$toaster.error(e);
       this.setLoading(false)
        this.setLoading('error')
      }
    },
    async getWeekMonthData($day) {
      try {
       this.setLoading(true)
        let res = await this.pullPulseOxAverage($day);
        this.chartData.data = [];
        if (!res.error) {
          this.chartData.data = res.spo2;
        } else {
          this.$toaster.error(res.msg);
        }
        this.view = $day;
       this.setLoading(false)
      } catch (e) {
        this.$toaster.error("Error fetching pulse data");
         this.setLoading('error')
      }
    },
  },
};
</script>
<style>
.v-toaster .v-toast-error .v-toast-btn-clear{
  margin-left: .25rem !important;
}
</style>